if (typeof console != 'object' &&
        typeof firebug !== 'object' ) {
    var console = {
        log: function() {},
        dir: function() {},
        info: function() {},
        assert: function() {}
    };
}

/*
    http://www.JSON.org/json2.js
    2011-02-23

    Public Domain.

    NO WARRANTY EXPRESSED OR IMPLIED. USE AT YOUR OWN RISK.

    See http://www.JSON.org/js.html


    This code should be minified before deployment.
    See http://javascript.crockford.com/jsmin.html

    USE YOUR OWN COPY. IT IS EXTREMELY UNWISE TO LOAD CODE FROM SERVERS YOU DO
    NOT CONTROL.


    This file creates a global JSON object containing two methods: stringify
    and parse.

        JSON.stringify(value, replacer, space)
            value       any JavaScript value, usually an object or array.

            replacer    an optional parameter that determines how object
                        values are stringified for objects. It can be a
                        function or an array of strings.

            space       an optional parameter that specifies the indentation
                        of nested structures. If it is omitted, the text will
                        be packed without extra whitespace. If it is a number,
                        it will specify the number of spaces to indent at each
                        level. If it is a string (such as '\t' or '&nbsp;'),
                        it contains the characters used to indent at each level.

            This method produces a JSON text from a JavaScript value.

            When an object value is found, if the object contains a toJSON
            method, its toJSON method will be called and the result will be
            stringified. A toJSON method does not serialize: it returns the
            value represented by the name/value pair that should be serialized,
            or undefined if nothing should be serialized. The toJSON method
            will be passed the key associated with the value, and this will be
            bound to the value

            For example, this would serialize Dates as ISO strings.

                Date.prototype.toJSON = function (key) {
                    function f(n) {
                        // Format integers to have at least two digits.
                        return n < 10 ? '0' + n : n;
                    }

                    return this.getUTCFullYear()   + '-' +
                         f(this.getUTCMonth() + 1) + '-' +
                         f(this.getUTCDate())      + 'T' +
                         f(this.getUTCHours())     + ':' +
                         f(this.getUTCMinutes())   + ':' +
                         f(this.getUTCSeconds())   + 'Z';
                };

            You can provide an optional replacer method. It will be passed the
            key and value of each member, with this bound to the containing
            object. The value that is returned from your method will be
            serialized. If your method returns undefined, then the member will
            be excluded from the serialization.

            If the replacer parameter is an array of strings, then it will be
            used to select the members to be serialized. It filters the results
            such that only members with keys listed in the replacer array are
            stringified.

            Values that do not have JSON representations, such as undefined or
            functions, will not be serialized. Such values in objects will be
            dropped; in arrays they will be replaced with null. You can use
            a replacer function to replace those with JSON values.
            JSON.stringify(undefined) returns undefined.

            The optional space parameter produces a stringification of the
            value that is filled with line breaks and indentation to make it
            easier to read.

            If the space parameter is a non-empty string, then that string will
            be used for indentation. If the space parameter is a number, then
            the indentation will be that many spaces.

            Example:

            text = JSON.stringify(['e', {pluribus: 'unum'}]);
            // text is '["e",{"pluribus":"unum"}]'


            text = JSON.stringify(['e', {pluribus: 'unum'}], null, '\t');
            // text is '[\n\t"e",\n\t{\n\t\t"pluribus": "unum"\n\t}\n]'

            text = JSON.stringify([new Date()], function (key, value) {
                return this[key] instanceof Date ?
                    'Date(' + this[key] + ')' : value;
            });
            // text is '["Date(---current time---)"]'


        JSON.parse(text, reviver)
            This method parses a JSON text to produce an object or array.
            It can throw a SyntaxError exception.

            The optional reviver parameter is a function that can filter and
            transform the results. It receives each of the keys and values,
            and its return value is used instead of the original value.
            If it returns what it received, then the structure is not modified.
            If it returns undefined then the member is deleted.

            Example:

            // Parse the text. Values that look like ISO date strings will
            // be converted to Date objects.

            myData = JSON.parse(text, function (key, value) {
                var a;
                if (typeof value === 'string') {
                    a =
/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/.exec(value);
                    if (a) {
                        return new Date(Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4],
                            +a[5], +a[6]));
                    }
                }
                return value;
            });

            myData = JSON.parse('["Date(09/09/2001)"]', function (key, value) {
                var d;
                if (typeof value === 'string' &&
                        value.slice(0, 5) === 'Date(' &&
                        value.slice(-1) === ')') {
                    d = new Date(value.slice(5, -1));
                    if (d) {
                        return d;
                    }
                }
                return value;
            });


    This is a reference implementation. You are free to copy, modify, or
    redistribute.
*/

/*jslint evil: true, strict: false, regexp: false */

/*members "", "\b", "\t", "\n", "\f", "\r", "\"", JSON, "\\", apply,
    call, charCodeAt, getUTCDate, getUTCFullYear, getUTCHours,
    getUTCMinutes, getUTCMonth, getUTCSeconds, hasOwnProperty, join,
    lastIndex, length, parse, prototype, push, replace, slice, stringify,
    test, toJSON, toString, valueOf
*/


// Create a JSON object only if one does not already exist. We create the
// methods in a closure to avoid creating global variables.

var JSON;
if (!JSON) {
    JSON = {};
}

(function () {
    "use strict";

    function f(n) {
        // Format integers to have at least two digits.
        return n < 10 ? '0' + n : n;
    }

    if (typeof Date.prototype.toJSON !== 'function') {

        Date.prototype.toJSON = function (key) {

            return isFinite(this.valueOf()) ?
                this.getUTCFullYear()     + '-' +
                f(this.getUTCMonth() + 1) + '-' +
                f(this.getUTCDate())      + 'T' +
                f(this.getUTCHours())     + ':' +
                f(this.getUTCMinutes())   + ':' +
                f(this.getUTCSeconds())   + 'Z' : null;
        };

        String.prototype.toJSON      =
            Number.prototype.toJSON  =
            Boolean.prototype.toJSON = function (key) {
                return this.valueOf();
            };
    }

    var cx = /[\u0000\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,
        escapable = /[\\\"\x00-\x1f\x7f-\x9f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,
        gap,
        indent,
        meta = {    // table of character substitutions
            '\b': '\\b',
            '\t': '\\t',
            '\n': '\\n',
            '\f': '\\f',
            '\r': '\\r',
            '"' : '\\"',
            '\\': '\\\\'
        },
        rep;


    function quote(string) {

// If the string contains no control characters, no quote characters, and no
// backslash characters, then we can safely slap some quotes around it.
// Otherwise we must also replace the offending characters with safe escape
// sequences.

        escapable.lastIndex = 0;
        return escapable.test(string) ? '"' + string.replace(escapable, function (a) {
            var c = meta[a];
            return typeof c === 'string' ? c :
                '\\u' + ('0000' + a.charCodeAt(0).toString(16)).slice(-4);
        }) + '"' : '"' + string + '"';
    }


    function str(key, holder) {

// Produce a string from holder[key].

        var i,          // The loop counter.
            k,          // The member key.
            v,          // The member value.
            length,
            mind = gap,
            partial,
            value = holder[key];

// If the value has a toJSON method, call it to obtain a replacement value.

        if (value && typeof value === 'object' &&
                typeof value.toJSON === 'function') {
            value = value.toJSON(key);
        }

// If we were called with a replacer function, then call the replacer to
// obtain a replacement value.

        if (typeof rep === 'function') {
            value = rep.call(holder, key, value);
        }

// What happens next depends on the value's type.

        switch (typeof value) {
        case 'string':
            return quote(value);

        case 'number':

// JSON numbers must be finite. Encode non-finite numbers as null.

            return isFinite(value) ? String(value) : 'null';

        case 'boolean':
        case 'null':

// If the value is a boolean or null, convert it to a string. Note:
// typeof null does not produce 'null'. The case is included here in
// the remote chance that this gets fixed someday.

            return String(value);

// If the type is 'object', we might be dealing with an object or an array or
// null.

        case 'object':

// Due to a specification blunder in ECMAScript, typeof null is 'object',
// so watch out for that case.

            if (!value) {
                return 'null';
            }

// Make an array to hold the partial results of stringifying this object value.

            gap += indent;
            partial = [];

// Is the value an array?

            if (Object.prototype.toString.apply(value) === '[object Array]') {

// The value is an array. Stringify every element. Use null as a placeholder
// for non-JSON values.

                length = value.length;
                for (i = 0; i < length; i += 1) {
                    partial[i] = str(i, value) || 'null';
                }

// Join all of the elements together, separated with commas, and wrap them in
// brackets.

                v = partial.length === 0 ? '[]' : gap ?
                    '[\n' + gap + partial.join(',\n' + gap) + '\n' + mind + ']' :
                    '[' + partial.join(',') + ']';
                gap = mind;
                return v;
            }

// If the replacer is an array, use it to select the members to be stringified.

            if (rep && typeof rep === 'object') {
                length = rep.length;
                for (i = 0; i < length; i += 1) {
                    if (typeof rep[i] === 'string') {
                        k = rep[i];
                        v = str(k, value);
                        if (v) {
                            partial.push(quote(k) + (gap ? ': ' : ':') + v);
                        }
                    }
                }
            } else {

// Otherwise, iterate through all of the keys in the object.

                for (k in value) {
                    if (Object.prototype.hasOwnProperty.call(value, k)) {
                        v = str(k, value);
                        if (v) {
                            partial.push(quote(k) + (gap ? ': ' : ':') + v);
                        }
                    }
                }
            }

// Join all of the member texts together, separated with commas,
// and wrap them in braces.

            v = partial.length === 0 ? '{}' : gap ?
                '{\n' + gap + partial.join(',\n' + gap) + '\n' + mind + '}' :
                '{' + partial.join(',') + '}';
            gap = mind;
            return v;
        }
    }

// If the JSON object does not yet have a stringify method, give it one.

    if (typeof JSON.stringify !== 'function') {
        JSON.stringify = function (value, replacer, space) {

// The stringify method takes a value and an optional replacer, and an optional
// space parameter, and returns a JSON text. The replacer can be a function
// that can replace values, or an array of strings that will select the keys.
// A default replacer method can be provided. Use of the space parameter can
// produce text that is more easily readable.

            var i;
            gap = '';
            indent = '';

// If the space parameter is a number, make an indent string containing that
// many spaces.

            if (typeof space === 'number') {
                for (i = 0; i < space; i += 1) {
                    indent += ' ';
                }

// If the space parameter is a string, it will be used as the indent string.

            } else if (typeof space === 'string') {
                indent = space;
            }

// If there is a replacer, it must be a function or an array.
// Otherwise, throw an error.

            rep = replacer;
            if (replacer && typeof replacer !== 'function' &&
                    (typeof replacer !== 'object' ||
                    typeof replacer.length !== 'number')) {
                throw new Error('JSON.stringify');
            }

// Make a fake root object containing our value under the key of ''.
// Return the result of stringifying the value.

            return str('', {'': value});
        };
    }


// If the JSON object does not yet have a parse method, give it one.

    if (typeof JSON.parse !== 'function') {
        JSON.parse = function (text, reviver) {

// The parse method takes a text and an optional reviver function, and returns
// a JavaScript value if the text is a valid JSON text.

            var j;

            function walk(holder, key) {

// The walk method is used to recursively walk the resulting structure so
// that modifications can be made.

                var k, v, value = holder[key];
                if (value && typeof value === 'object') {
                    for (k in value) {
                        if (Object.prototype.hasOwnProperty.call(value, k)) {
                            v = walk(value, k);
                            if (v !== undefined) {
                                value[k] = v;
                            } else {
                                delete value[k];
                            }
                        }
                    }
                }
                return reviver.call(holder, key, value);
            }


// Parsing happens in four stages. In the first stage, we replace certain
// Unicode characters with escape sequences. JavaScript handles many characters
// incorrectly, either silently deleting them, or treating them as line endings.

            text = String(text);
            cx.lastIndex = 0;
            if (cx.test(text)) {
                text = text.replace(cx, function (a) {
                    return '\\u' +
                        ('0000' + a.charCodeAt(0).toString(16)).slice(-4);
                });
            }

// In the second stage, we run the text against regular expressions that look
// for non-JSON patterns. We are especially concerned with '()' and 'new'
// because they can cause invocation, and '=' because it can cause mutation.
// But just to be safe, we want to reject all unexpected forms.

// We split the second stage into 4 regexp operations in order to work around
// crippling inefficiencies in IE's and Safari's regexp engines. First we
// replace the JSON backslash pairs with '@' (a non-JSON character). Second, we
// replace all simple value tokens with ']' characters. Third, we delete all
// open brackets that follow a colon or comma or that begin the text. Finally,
// we look to see that the remaining characters are only whitespace or ']' or
// ',' or ':' or '{' or '}'. If that is so, then the text is safe for eval.

            if (/^[\],:{}\s]*$/
                    .test(text.replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, '@')
                        .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
                        .replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {

// In the third stage we use the eval function to compile the text into a
// JavaScript structure. The '{' operator is subject to a syntactic ambiguity
// in JavaScript: it can begin a block or an object literal. We wrap the text
// in parens to eliminate the ambiguity.

                j = eval('(' + text + ')');

// In the optional fourth stage, we recursively walk the new structure, passing
// each name/value pair to a reviver function for possible transformation.

                return typeof reviver === 'function' ?
                    walk({'': j}, '') : j;
            }

// If the text is not JSON parseable, then a SyntaxError is thrown.

            throw new SyntaxError('JSON.parse');
        };
    }
}());

var generic = generic || {};

generic.cookie = function(/*String*/name, /*String?*/value, /*.__cookieProps*/props){
	var c = document.cookie;
	if (arguments.length == 1) {
		var matches = c.match(new RegExp("(?:^|; )" + name + "=([^;]*)"));
		if (matches) {
			matches = decodeURIComponent(matches[1]);
			try {
			     return JSON.parse(matches); //Object
			} catch(e) {
			     return matches; //String
			}

		} else {
			return undefined;
		}
	} else {
		props = props || {};
// FIXME: expires=0 seems to disappear right away, not on close? (FF3)  Change docs?
		var exp = props.expires;
		if (typeof exp == "number"){
			var d = new Date();
			d.setTime(d.getTime() + exp*24*60*60*1000);
			exp = props.expires = d;
		}
		if(exp && exp.toUTCString){ props.expires = exp.toUTCString(); }

		value = encodeURIComponent(value);
		var updatedCookie = name + "=" + value;

		for(propName in props){
			updatedCookie += "; " + propName;
			var propValue = props[propName];
			if(propValue !== true){ updatedCookie += "=" + propValue; }
		}
		//console.log(updatedCookie);
		document.cookie = updatedCookie;
	}
};
var generic = generic || {};

generic.forms = {
    select : {
        addOption:  function(args) {
            if (!args) return;
            var val = args.value;
            var label = args.label || val;
            var opt = '<option value="' + val + '">' + label + '</option>';

            args.menuNode.append(opt);
        },
        setValue: function(args) {
            var idx = 0;
            for (var i=0, len=args.menuNode[0].options.length; i<len; i++) {
                if (args.value == args.menuNode[0].options[i].value) {
                    idx = i;
                    break;
                }
            }
            args.menuNode[0].selectedIndex = idx;
        }
    }
};


var generic = generic || {};
generic.rb = generic.rb || {};

var rb = rb || {};

/**
* This method provides access to resource bundle values that have been
* written to the HTML in JSON format. The file that outputs these values
* must be included in the .html as a script tag with the desired RB name
* as a query string paramter.
*
* @class ResourceBundle
* @namespace generic.rb
*
* @memberOf generic
* @methodOf generic
* @requires generic.Hash (minimal functional replication of Prototype Hash Class)
*
* @example Inline data
*
*    <script src="/js/shared/v2/internal/resource.tmpl?rb=account"></script>
*
* @example Script retrival of data values
*
*    var myBundle = generic.rb("account");
*    myBundle.get("err_please_sign_in");
*
*
* @param {String} rbGroupName name of resource bundle needed
*
* @returns An object that provides the main get method
*
*/
generic.rb = function(rbGroupName) {

    var findResourceBundle = function(groupName) {

        if (groupName && rb) {

            var rbName = groupName;
            var rbHash = generic.Hash(rb[rbName]);
            if (rbHash) {
                return rbHash;
            } else {
                return $H({});
            }
        } else {
            return $H({});
        }

    };

    var resourceBundle = findResourceBundle(rbGroupName);

    var returnObj = {
        /**
        * @public This method will return the value for the requested Resource Bundle key.
        * If the key is not found, the key name will be returned.
        *
        * @param {String} keyName key of desired Resource Bundle value
        */
        get: function(keyName) {
            if ( !generic.isString(keyName) ) {
                return null;
            }
            var val = resourceBundle.get(keyName);
            if (val) {
                return val;
            } else {
                return keyName;
            }
        }
    };

    return returnObj;

};
var generic = generic || {};

/**
 * Template.js
 *
 * @memberOf generic
 *
 * @class TemplateSingleton
 * @namespace generic.template
 *
 * @requires object literal with parameters
 *
 * @param path attribute as a literal key is required
 * @example "/templates/cart-overlay.tmpl",
 *
 * @param {string} templateString takes first priority
 * @example templateString:'#{product.url} some-page-markup-with-#{product.url}'
 *
 * @param {boolean} forceReload
 *
 * @param {function} callback
 * @example
 *
 * callback:function(html) {
 *    // Front-End Resolution
 *    $('#container').html(html);
 * }
 *
 * @param {object} query object hash with object-literals, array-literals that can be nested
 * @example example structure
 * query: {
 *    a:'',b:{},c:[],d:{[]} // keys rooted to named parent if object or array-objects are nested
 * }
 *
 * @param {object} Hash of string-literals with string values that map to the template
 * @example
 *
 * object: {
 *    'product.PROD_RGN_NAME':'replacement',
 *    SOME_VAR:'replacement'
 * }
 *
 * @example Usage
 *
 * generic.template.get({
 *    path:"/some/path/to/template.tmpl",
 *    ...
 * });
 *
 * @param {HTML} (optional) Markup based inline template
 * @required The path attribute must match the path key passed to the get method.
 *
 * @example Inline Template Example
 *
 * <!-- -------------------------- Inline Template ------------------------------ -->
 *
 * <script type="text/html" class="inline-template" path="templates/foo.tmpl">"
 *         <div>#{FIRST_NAME}</div>
 *         <div>#{SECOND_NAME}</div>
 * </script>
 *
 * Inline Templates : Valid inline template via script tag in this format, aside
 * from the templateString parameter, will be the first candidate for the template,
 * then the cache, then ajax.
 *
 *
 * @returns {object} An object that refers to a singleton which provides
 * the primary get api method.
 *
 */


generic.template  = ( function() {

    var that = {};
    var templateClassName = ".inline-template";
    var templates = {};

    /**
     * This method loads a pre-interpolation template into the object's internal cache. This cache is checked before attempting to pull the template from the DOM or load it via Ajax.
     * @param (String) key The name that is used to retrieve the template from the internal cache. Typically mathces the path for Ajax-loaded templates.
     * @param (String) html The non-interpoltaed content of the template.
     * @returns (Strin) the HTML that was originally passed in
     * @private
     */
    var setInternalTemplate = function(key, html) {
        templates[key] = html;
        return html;
    };

    var getInternalTemplate = function(key) {
        return templates[key];
    };

    var returnTemplate = function(args) {
        if (typeof args.object === "object") {
            var html = interpolate(args.template, args.object);
        }else{
			var html = args.template;
		}
        if (typeof args.callback === "function") {
            args.callback(html);
        }
    };

    var interpolate = function(template, obj) {
        var obj = obj || {};
        var tmpl = template, Lre = new RegExp("\#\{"), Rre = new RegExp("\}"), tmplA = [], temp, lft, rght;

        tmplA = tmpl.replace(/[\r\t\n]/g," ").split(Lre); // array of (.+?)} with '}' marking key vs rest of doc

        var returnString = "";
        for(var x = 0; x < tmplA.length; x++) {
            var chunk = tmplA[x];
            var splitChunk = chunk.split(Rre);

			// FIXME TODO: Embarrassingly ham handed approach to setting url_domain template variable for IE (bug i73662)
			//  Needs someone more familiar with javascript to find out why this error only occurs in IE
			//	 with the url_domain object value set anywhere but here (setting it elsewhere works fine in FF)
			if (splitChunk[0] == 'url_domain') {
				splitChunk[1] = 'http://' + document.location.hostname;
			}
            if (typeof splitChunk[1] !== "undefined") { // close tag is found
                // First check array notation for property names with spaces
                // Then check object notation for deep references
                var valueToInsert = eval("obj['" + splitChunk[0] +"']" ) || eval("obj." + splitChunk[0] );
                if (typeof valueToInsert === "undefined" || valueToInsert === null) {
                    valueToInsert = '';
                }
                chunk = valueToInsert.toString() + splitChunk[1];
            }
            returnString += chunk;
        }
        return returnString;
    };

    that.get = function( args ) {
        var key = args.path;
        var callback = args.callback;
        var forceReload = !!args.forceReload;
        var objectParam = args.object;
        var template = getInternalTemplate(key);

        if (template && !forceReload) {  // internal template found and OK to use cache
            returnTemplate({
                template: template,
                object: objectParam,
                callback: args.callback
            })
        } else {  // no internal template found or not OK to use cache
            // attempt to retrieve from DOM
            var matchingTemplateNode = null;
            $(templateClassName).each( function() {
                if ( $(this).html() && ( $(this).attr("path")==key) ) {
                    matchingTemplateNode = this;
                }
            });
            if (matchingTemplateNode) { // inline template found in DOM
                template = setInternalTemplate( key, $(matchingTemplateNode).html() );
                returnTemplate({
                    template: template,
                    object: args.object,
                    callback: args.callback
                })
            } else { // not found inline


                $.ajax({
                    url: key,
                    context: this, // bind (.bind onSuccess callback)
                    data: args.urlparams,
                    success: function(data, textStatus, jqXHR){
                        template = setInternalTemplate( key, jqXHR.responseText);
                        returnTemplate({
                            template: template,
                            object: args.object,
                            callback: args.callback
                        })
                    }
                });
            }
        }

    };

    return that;

})();
//
// generic.TemplateSingleton = (function(){
//
//     /**
//      * @private singleton reference
//      */
//     var singleton;
//
//     /**
//      * @inner Template constructor
//      * @constructs Template object
//      */
//     var Template = function ( template, pattern ) {
//         this.template = template?template:'';
//         this.readyState = template?1:0;
//         this.pattern = pattern?pattern:new RegExp("\#\{(.+?)\}");
//         this.queue = new Array();
//
//         /**
//          * @private
//          */
//         var A = {
//             evaluate : function(replacements){
//                 var tmpl = this.template, Lre = new RegExp("\#\{"), Rre = new RegExp("\}"), tmplA = [], temp, lft, rght;
//
//             // reference : ob.replace(/[\r\t\n]/g," ").split("<").join("Y").split(">").join("X");
//             tmplA = tmpl.replace(/[\r\t\n]/g," ").split(Lre); // array of (.+?)} with '}' marking key vs rest of doc
//
//             for(var x = 0; x < tmplA.length; x++) {
//
//                 // Array.split returns differently for IE, test for undefined
//                 lft = (replacements[tmplA[x].split(Rre)[0]]===undefined)?'':replacements[tmplA[x].split(Rre)[0]]
//                 rght = (tmplA[x].split(Rre)[1]===undefined)?'':tmplA[x].split(Rre)[1];
//
//                 tmplA[x] = lft + rght;
//
//                 }
//                 tmpl = tmplA.join('');
//
//                 return tmpl;
//
//             },
//             Pattern : new RegExp("\#\{(.+?)\}")
//         }
//
//         $.extend(this,A,{
//             //test: function(){alert('test at prototype level');}, // prototype set as object, not a constructor
//             load: function(template) {
//                 this.template = template.toString();
//                 this.readyState = 1;
//                 this.onReadyState();
//             },
//             evaluateCallback: function (options) {
//                 this.options = {
//                     object: {},
//                     callback: function () {}
//                 };
//                 this.options = $.extend(this.options, options || { });
//
//                       /**
//                        * @private This is tied to templateString. If passed,
//                        * then onReadyState queue bypassed, and control goes straight
//                        * to the callbackEvaluation via readState true
//                        */
//                     if (this.readyState) {
//                         this.options.callback(this.evaluate(this.options.object));
//
//                     } else {
//                         this.queue.push({
//                             qtype: 'callback',
//                             obj: this.options.object,
//                             fnc: this.options.callback
//                         });
//                     }
//                 return;
//             },
//             // Asynchronous to .evaluateCallback
//             onReadyState: function () {
//                 while (q = this.queue.shift()) {
//                     var object = q.obj;
//                     var qtype = q.qtype;
//                     var callback = q.fnc;
//
//                     callback(this.evaluate(object));
//                 }
//             }
//         });
//     }
//
//     /**
//      * @description Single object with main method that controls for
//      * the switch among templateString, inline, cache, ajax is delegated.
//      *
//      * @inner Constructor
//      * @constructs an object with main method namespace
//      * @class GetFunctionObject
//      */
//     var GetFunctionObject = (new function() {
//
//         /**
//          * @private
//          */
//         var defaults = { useInline:true, templateCssClass : ".inline-template" }, _object  = []; // internal prop
//
//         this.debug = function() { var msg = 'private var defaults : '+defaults.useInline; alert(msg); }
//
//         /**
//          * @private
//          * @function primary function (method) as value bound to public get api method
//          */
//         this.main = function (params) {
//
//             /**
//              * @private
//              */
//             var key = params.path, query = params.query,
//                 forceReload = params.forceReload || false,
//                 templateString = params.templateString || false,
//                 useInline, templateClassName;
//
//             // if no templateString-override, then setup for inline script tag template
//             if(!templateString) {
//                 useInline = (params.useInline)?params.useInline:defaults.useInline;
//                 templateClassName = (params.templateClassName)?params.templateClassName:defaults.templateCssClass;
//             }
//             /* these controls currently closed
//             if(params.useInline && params.templateClassName) {
//                 defaults.useInline = params.useInline;
//                 defaults.templateClassName = params.templateClassName;
//             }
//             */
//
//             // Case 1: Brand New : forceReload, new query, and no template yet
//             if (typeof _object[key] != "undefined" && !forceReload && !query) {
//                 return _object[key];
//             }
//             _object[key] = new Template();
//             //this._object[key].test(); // hits new inner Class of Template
//
//             // Case 2: Template string directly passed
//             if (templateString) {
//                 _object[key].load(templateString);
//                 return _object[key].evaluateCallback(params);
//             }
//             var url = key;
//             if (query) {
//                 var q = $H(query);
//                 var queryString = q.toQueryString();
//                 url += "?" + queryString;
//             }
//             // Attempt to Use Inline
//             if(useInline) {
//                 $(templateClassName).each(function(){
//                     if($(this).html()&&($(this).attr("path")==key)) {
//                      alert($(this).html());
//                         _object[key].load( $(this).html() );
//                     }
//                 });
//             }else{
//              // load asynchronously and move onto evaluateCallback
//              $.ajax({
//                  url: url,
//                  context: this, // bind (.bind onSuccess callback)
//                  data: params.urlparams,
//                  success: function(data, textStatus, jqXHR){
//                      _object[key].load(jqXHR.responseText);
//                  }
//              });
//          }
//              _object[key].evaluateCallback(params);
//
//         return this;
//         }
//     }());
//
//     var PublicInterfaceMapper = { get : GetFunctionObject.main, sample : GetFunctionObject.debug };
//     var API = $.extend(PublicInterfaceMapper,GetFunctionObject);
//
//     return function () {
//
//         // Return Same Obj
//         if(singleton) { return singleton; }
//
//             // Extra api
//             singleton = $.extend(this,API);
//             singleton.api = API.sample;
//
//         //alert('return singleton; //* should only see this once *// ');
//         };
//     }());
//
// generic.template = new generic.TemplateSingleton();

//
// TODO: check that all fields exist before returning/fetching data
// TODO: accept product & category ID args as arrays
//
/**
 * @namespace
 */
var generic = generic || {};

/**
 * @namespace
 */
generic.productData = generic.productData || {};


// document.observe('dom:loaded', function (evt) {
    // check pagedata for product data
// });


/**
 * This constructor method creates and returns a productCatalog singleton object.
 * The method executes immediately on load.
 * object that executes
 * @return {Object} product catalog object
 * @namespace
 * @methodOf generic
 */
generic.productData = ( function() {
    // var data = {
    //     categories : [{
    //         CATEGORY_ID : "",
    //         products: [{
    //             PRODUCT_ID : "",
    //             skus: [{
    //                 SKU_ID : ""
    //             }]
    //         }]
    //     }]
    // };
    // var data = {};
    var data = {
        categories : []
    };
    var defaultCategoryFields = ["CATEGORY_ID", "CAT_BASE_ID", "CATEGORY_NAME"];
    var defaultProductFields = ["PRODUCT_ID", "DEFAULT_CAT_ID", "PARENT_CAT_ID", "PROD_RGN_NAME", "PROD_RGN_SUBHEADING", "SUB_LINE", "DESCRIPTION", "SHORT_DESC", "PROD_SKIN_TYPE", "PROD_SKIN_TYPE_TEXT", "PROD_CAT_IMAGE_NAME", "PROD_CAT_DISPLAY_ORDER", "SMALL_IMAGE", "LARGE_IMAGE", "THUMBNAIL_IMAGE", "PRODUCT_USAGE", "FORMULA", "ATTRIBUTE_COVERAGE", "ATTRIBUTE_BENEFIT", "SKIN_CONCERN_LABEL", "SKIN_CONCERN_1", "SKIN_CONCERN_2", "SKIN_CONCERN_3", "skus", "shaded", "sized", "worksWith"];
	var defaultSkuFields = ["SKU_ID", "SKU_BASE_ID", "PRODUCT_ID", "SHADENAME", "SHADE_DESCRIPTION", "SKIN_TYPE", "SKIN_TYPE_TEXT", "PRODUCT_SIZE", "DISPLAY_ORDER", "STRENGTH", "PRICE", "formattedPrice", "formattedTaxedPrice", "SMOOSH_DESIGN", "SMOOSH_PATH_STRING", "INVENTORY_STATUS", "REFILLABLE", "HEX_VALUE", "HEX_VALUE_STRING", "FINISH", "ATTRIBUTE_COLOR_FAMILY", "UNDERTONE", "SKIN_TONE", "SKIN_TONE_TEXT" ];

    var fetchData = function(args) {
        // check for relevant page_data
        /********* COMMENTED OUT UNTIL page_data and Analytics are converted ************/
        if (args.pageDataKey) {
            var catalogPageData = generic.page_data(args.pageDataKey);
            if (catalogPageData.get("rpcdata")) {
                 //Notify analytics.js that this exists instead of RPC data
                // document.fire("PAGEDATA:RESULT",args.pageDataKey);
                $(document).trigger("PAGEDATA:RESULT", [args.pageDataKey]);
                args.callback(catalogPageData.get("rpcdata"));
                return;
            }
        }

        var jsonRpcParams = {};
        if (args.categoryId) {
            jsonRpcParams.categories = [args.categoryId];
        }
        if (args.productId) {
            jsonRpcParams.products = [args.productId];
        } else if (args.productIds && Array.isArray(args.productIds)) {
            jsonRpcParams.products = args.productIds;
        }
        if (args.categoryFields) {
            jsonRpcParams.category_fields = args.categoryFields;
        }
        if (args.productFields) {
            jsonRpcParams.product_fields = args.productFields;
        }
        if (args.skuFields) {
            jsonRpcParams.sku_fields = args.skuFields;
        }
        generic.jsonrpc.fetch({
            method: "prodcat",
            params: [jsonRpcParams],
            onSuccess: function(jsonRpcResponse) {
                args.callback(jsonRpcResponse.getValue());
            },
            onFailure: function (jsonRpcResponse) {}
        });
    };
    var getInternalCategoryData = function(args) {
        if (!( args.categoryId && data && Array.isArray(data.categories) )) {
            return null;
        }
        var categoryMatch = null;
        for (var i=0, len=data.categories.length; i<len; i++) {
            var c = data.categories[i];
            if (c.CATEGORY_ID == args.categoryId) {
                categoryMatch = c;
                break;
            }
        }
        return categoryMatch;
    };
    var getInternalProductData = function(args) {
        if (!( args.productId && data && Array.isArray(data.categories) )) {
            return null;
        }
        var productMatch = null;
        var categoryMatch = null;
        if (args.categoryId) {
            categoryMatch = getInternalCategoryData({categoryId : args.categoryId});
            if (categoryMatch && Array.isArray(categoryMatch.products)) {
                for (var i=0, len=categoryMatch.products.length; i<len; i++) {
                    var p = categoryMatch.products[i];
                    if (p.PRODUCT_ID == args.productId) {
                        productMatch = p;
                        break;
                    }
                }
            }
        } else {
            for (var i=0, len=data.categories.length; i<len; i++) {
                var category = data.categories[i];
                if (Array.isArray(category.products)) {
                    for (var j=0, jlen=categoryMatch.products.length; j<jlen; j++) {
                        var p = categoryMatch.products[j];
                        if (p.PRODUCT_ID == args.productId) {
                            productMatch = p;
                            break;
                        }
                    }
                }
                if (productMatch) {
                    break;
                }
            }
        }
        return productMatch;
    };

    /** @scope generic.productCatalog */
    return {
        /**
         * Returns product data for a given product ID.
         * @method getProductData
         * @public
         */
        getProductData : function (args) {

            if (!( args.productId)) {
                return null;
            }


            var productMatch = getInternalProductData({
                productId: args.productId,
                categoryId: args.categoryId
            });
            if (productMatch) {
                args.callback(productMatch);
            } else {
                var self = this;
                // to get the correct product data, we have to concatenate the Cat ID
                // and Prod ID with a ~ character and pass the resulting value as the product ID.
                var compoundProductId = args.productId;
                if (args.categoryId) {
                    compoundProductId = args.categoryId + '~' + args.productId;
                }

                fetchData({
                    pageDataKey: args.pageDataKey,
                    productId: compoundProductId,
                    categoryFields: args.categoryFields,
                    productFields: args.productFields,
                    skuFields: args.skuFields,
                    callback: function(responseData) {
                        self.setProductData(responseData);
                        var returnData = getInternalProductData({
                            productId: args.productId,
                            categoryId: args.categoryId
                        });
                        args.callback(returnData);
                    }
                });
            }
        },
        getProductsData : function (args) {
            if (!( args.productIds && Array.isArray(args.productIds) )) {
                return null;
            }
            var productsDataArray = [];
            var productIdsToFetch = [];

            for (var i=0, len=args.productIds; i<len; i++) {
                var prodId = args.productIds[i];
                var productMatch = getInternalProductData({
                    productId: prodId
                });
                if (productMatch) {
                    productsDataArray.push(productMatch);
                } else {
                    productIdsToFetch.push(prodId);
                }
            }

            fetchData({
                pageDataKey: args.pageDataKey,
                productIds: productIdsToFetch,
                categoryFields : args.categoryFields || defaultCategoryFields,
                productFields : args.productFields || defaultProductFields,
                skuFields : args.skuFields || defaultSkuFields,
                callback: function(responseData) {
                    // self.setProductData(responseData);
                    // var returnData = getInternalProductData({
                    //     productId: args.productId,
                    //     categoryId: args.categoryId
                    // });
                    args.callback(responseData);
                }
            });
        },
        getCategoryData : function (args) {
            if ( !args.categoryId ) {
                return null;
            }
            var categoryMatch = getInternalCategoryData({
                categoryId: args.categoryId
            });
            if (categoryMatch) {
                args.callback(categoryMatch);
            } else {
                var self = this;
                fetchData({
                    pageDataKey: args.pageDataKey,
                    categoryId: args.categoryId,
                    productFields: args.productFields,
                    categoryFields: args.categoryFields,
                    skuFields: args.skuFields,
                    callback: function(responseData) {
                        self.setCategoryData(responseData);
                        args.callback(getInternalCategoryData({categoryId: args.categoryId}));
                    }
                });
            }
        },
        addCategory : function (newCategoryData) {
            if (!newCategoryData.CATEGORY_ID) {
                return null;
            }
            data.categories = data.categories || [];
            data.categories.push(newCategoryData);
            return newCategoryData;
        },
        addProduct : function (newProductData) {
            var categoryID = newProductData.PARENT_CAT_ID;
            var category = getInternalCategoryData({categoryId: categoryID});
            if (!category) {
                category = this.addCategory({
                    CATEGORY_ID: categoryID,
                    products: []
                });
            }
            category.products.push(newProductData);
        },
        setCategoryData: function(args) {
            if ( args.categories && Array.isArray(args.categories) ) {
                for (var i=0, len=args.categories.length; i<len; i++) {
                    var newCategory = args.categories[i];
                    if ( !newCategory.CATEGORY_ID ) {
                        return null;
                    }
                    var oldCategory = getInternalCategoryData({
                        categoryId : newCategory.CATEGORY_ID
                    });
                    if (oldCategory) {
                        $.extend(oldCategory, newCategory);
                    } else {
                        this.addCategory(newCategory);
                    }
                }
            }

        },
        setProductData : function (args) {
            if ( args.products && Array.isArray(args.products) ) {
                for (var i=0, len=args.products.length; i<len; i++) {
                    var newProduct = args.products[i];

                    if ( !(newProduct.PARENT_CAT_ID && newProduct.PRODUCT_ID) ) {
                        return null;
                    }
                    var oldProduct = getInternalProductData({
                        productId : newProduct.PRODUCT_ID,
                        categoryId : newProduct.PARENT_CAT_ID
                    });
                    if (oldProduct) {
                        $.extend(oldProduct, newProduct);
                    } else {
                        this.addProduct(newProduct);
                    }
                };
            }
        },
        clearAllData : function () {
            data = {};
        },

        validateSkusArray : function(skus) {
        	return (skus && Array.isArray(skus) && skus.length > 0);
        },

        /**
         * This method returns a string that displays both post and pre-tax prices for a given SKU.
         * Locale-specific characters and format are hard-coded in the method.
         * @return {String} formatted string with pre- & post-tax prices
         * @param {Object} sku JSON-formatted SKU data. Must include formattedTaxedPrice and formattedPrice properties.
         * @param {String} separator Optional string that gets placed between both prices. Default is a space.
         * @methodOf generic.productData
         */
        getPriceDisplay : function (sku, separator) {
            if (sku && sku.formattedTaxedPrice && sku.formattedPrice) {
                var preTaxLabel  = '&#26412;&#20307;';
                var postTaxLabel = '&#31246;&#36796;';
                var openBracket = '( ';
                var closeBracket = ')';
                if (!separator) separator = ' ';
                var price_string = '';
                price_string = postTaxLabel + sku.formattedTaxedPrice + separator + openBracket + preTaxLabel + sku.formattedPrice + closeBracket;
                return price_string;
            } else {
                return '';
            }
        }

     };
} )();



var generic = generic || {};
generic.page_data = generic.page_data || {};
var page_data = page_data || {};

generic.page_data = function(pageDataKey) {
    var getPageDataValue = function(pageDataKey) {

        if (typeof pageDataKey != "string") {
            return $H({});
        }
        // avoid deep cloning of page_data
        if (pageDataKey && page_data) {
            var key = pageDataKey;
            var parts = key.split(".");
            var length = parts.length;
            var val = page_data;
            var k;
            while (k = parts.shift()) {
                if (val[k]) {
                    val = val[k];
                }
                else {
                    return $H({});
                }
            }
            var rh;
            // For scalars and arrays make a return hash where the key is the pageDataKey
            if (typeof val == "string"
                    || typeof val == "number"
                    || Array.isArray(val)) {
                var t = new Object;
                t[pageDataKey] = val;
                rh = $H(t);
            }
            else {
                rh = $H(val);
            }

            if (rh) {
                return rh;
            }
            else {
                return $H({});
            }
        }
        else {
            return $H({});
        }
    };
    var pageDataValue = getPageDataValue(pageDataKey);
    var returnObj = {
        get: function(key) {
            if ( typeof key != "string") {
                return null;
            }
            var val = pageDataValue.get(key);
            return val;
        }
    };
    return returnObj;
};



generic.user = {};

/**
 * generic.user
 * - depends on: generic.jsonrpc
 */
generic.user = (function(){

    return {
        signed_id : false,

        initialize: function(args) {
            generic.updateProperties.apply(this, [args]);
        },

        getUser: function(args) {
            var self = this;

            if (args && args.pageDataKey) {
                var pageData = generic.page_data(args.pageDataKey);
                if (pageData.get("rpcdata")) {
                    console.log( "user page data found!");
                    self._updateUserData(pageData.get("rpcdata"));
                    return;
                }
            }

            var id = generic.jsonrpc.fetch({
                method : 'user.json',
                params: [],
                onSuccess: function(jsonRpcResponse) {
                    self._updateUserData(jsonRpcResponse.getValue());
                },
                onFailure: function(jsonRpcResponse) {
                    console.log('User JSON failed to load');
                }
            });
            return id;
        },

        // until we better parameterise this...
        _updateUserData: function(data) {
            var seld = this;
            if (data && !data[this.userinfo_rpc_key]) {
                $.extend( this, data[this.userinfo_rpc_key] );
            } else {
                $.extend( this, data );
            }
            //generic.events.fire({event:'user:updated'});
        },

        isSignedIn: function() {
            return ( this.signed_in ? true : false );
        }

    };
}() );

(function($) {
  clearTimeout(window.signOutTimer);
  $(function() {
    var site = window.site || {};
    // Don't do it if the user isn't signed in:
    var signed_in = typeof site.userInfoCookie === 'undefined' ? 0 : site.userInfoCookie.getValue('signed_in');
    var signOutDuration = Drupal.settings.signout_duration ? Drupal.settings.signout_duration : 15;
    signed_in = parseInt(signed_in, 10);
    if (signed_in) {
      window.signOutTimer = window.setTimeout(function() {
        $.cookie('showsignin', 1);
        document.location.href = '/account/signin.tmpl?_SUBMIT=signout';
      }, signOutDuration * 60 * 1000);
    }
  });
})(jQuery);

(function($) {
  window.generic = generic || {};

  // Route the old perlgem overlay method to colorbox:
  generic.overlay = {
    launch : function(args, event) {
      if (typeof event !== 'undefined' ) {
        event.preventDefault();
      }
      // ColorBox args sent along
      var cboxArgs = {
          'height': '600px',
          'width' : '768px',
          'margin' : 'auto',
        };
      // Smoosh in any overrides from other calls, looks like args.cssStyle
      _.extend(cboxArgs, args);
      _.extend(cboxArgs, args.cssStyle); // get height/width overrides

      // When mobile, override any height/width and set to 100%
      if ($(window).width() <= 768) {
        _.extend(cboxArgs, {height: '100%', width: '100%'});
          // When mobile, If default Height is true ,override any height set to auto
          var isDefaultHeight = (typeof args.isDefaultHeight !== 'undefined' ? args.isDefaultHeight: false);
          if (isDefaultHeight) {
            _.extend(cboxArgs, {height: 'auto'});
          }
      }
      // Actual content of the overlay
      if (typeof args.content !== 'undefined') cboxArgs.html = args.content;
      // A custome class each launcher has the option of setting
      if (typeof args.cssClass !== 'undefined') cboxArgs.className = args.cssClass;
      // Scroll to an anchor, if sent over
      if (typeof args.inPageAnchor !== 'undefined') {
        cboxArgs.onComplete = function() {
          $('#cboxLoadedContent').scrollTo($('#' + args.inPageAnchor), 50);
        };
      }

      // Cursor issue on IOS11.X
      if (generic.env.isIOS11) {
        var $body = $('html');
        cboxArgs.onComplete = function() {
          $body.addClass('body__fixed');
        };
        cboxArgs.onClosed = function() {
          $body.removeClass('body__fixed');
        };
      }
      // Launch it
      $.colorbox(cboxArgs);
    },

    initLinks: function() {
      // Give us access to the parent scope so we can hit .launch()
      var self = this;
      // Links are tiggered via class, but indicate if already processed
      var $triggers = $('.overlay-link:not(.overlay-ready)').addClass('overlay-ready');

      // Depending on the type of link, the overlay needs to do something unique
      $triggers.each( function() {
        var args = {
          cssStyle: {}
        }, // args sent to overlay
          linkClassNames = $(this).attr('class'), // class name sent to colorbox
          linkHref = $(this).attr('href'), // actual href
          linkHrefWithEmbed = linkHref,
          inPageAnchor = $(this).data('inpage-anchor'), // see try/catch below
          overlayElement = $(this).data('overlay-content'); // use an existing element as content

        // used in overlay linking below
        var urlParts = document.createElement('a'); //
        urlParts.href = linkHref; //

        // Parse height options out of the link's class
        var widthRegexResults = linkClassNames.match(/overlay-width-(\d+)/);
        if (widthRegexResults) {
          args.cssStyle.width = widthRegexResults[1];
        }
        // Parse width options
        var heightRegexResults = linkClassNames.match(/overlay-height-(\d+)/);
        if (heightRegexResults) {
          args.cssStyle.height = heightRegexResults[1];
        }
        // Add a custom class, optionally
        var cssClassRegexResults = linkClassNames.match(/overlay-addclass-([a-z\-\_]+)/);
        if (cssClassRegexResults) {
          args.className = cssClassRegexResults[1];
        }

        // Make sure embed doesn't already exist. This gets added form internal
        // drupal embeddable urls
        if (typeof overlayElement !== 'undefined') {
          args.content = $(overlayElement).html();
        } else {
	  var pathName = urlParts.pathname;
          try {
            if ((generic.env.isIE || generic.env.isIE11) && pathName.charAt(0) != '/') {
              pathName = '/'+pathName;
            }
            if( !linkHref.match(/[\&\?]embed=1($|&)/)) {
              linkHrefWithEmbed = pathName + (urlParts.search === "" ? "?" : urlParts.search+"&") + "embed=1" + urlParts.hash;

              // Retain original link if it included the protocol.
              if(linkHref.match(/https?:\/\//)) {
                linkHrefWithEmbed = urlParts.protocol + "//" + urlParts.host + linkHrefWithEmbed;
              }
            }
          } catch(e) {
            linkHrefWithEmbed = linkHref;
          }

          // Fix the link within the page
          $(this).attr('href', linkHrefWithEmbed);
          // But this is actually used to launch overlay
          args.href = linkHrefWithEmbed;
        }

        // scrollTo behavior if we have a data attribute
        if (typeof inPageAnchor !== 'undefined') {
          args.inPageAnchor = inPageAnchor;
        }

        // Launch a colorbox overlay
        $(this).on('click', function(e) {
          // use our canonical launch function for all the goodies
          self.launch(args, e);
        });

      }); // .each()

    }, // initLinks

    resize: function() {
      $.colorbox.resize();
    },

    hide: function() {
      $.colorbox.close();
    },

    getRBKeys: function() {
      generic.rb.language = generic.rb("language");
      generic.rb.language.rb_close = generic.rb.language.get('close');
    }

  };

  ($)(function(){
    generic.overlay.getRBKeys();
    generic.overlay.initLinks();
  });

})(jQuery);

(function($) {

window.site = site || {};
site.loyalty = site.loyalty || {};
var gdpr_sms = Drupal.settings.globals_variables.loyalty_gdpr_with_sms;
var adv_legal = Drupal.settings.loyalty && Drupal.settings.loyalty.force_sms_email_agrees;
var settingsLoyalty = typeof Drupal.settings.loyalty === 'object' ? Drupal.settings.loyalty : {};
var settingsLoyaltyOnlineUser = typeof settingsLoyalty.online_user === 'object' ? settingsLoyalty.online_user : {};

site.loyalty = {
  landingLoadCallback: function(context) {
      $(function() {
        if (settingsLoyaltyOnlineUser.update_permission_chechbox) {
          var phoneNumber = site.userInfoCookie.getValue('mobile_number') ? site.userInfoCookie.getValue('mobile_number') : undefined;
          var smsPromotions = site.userInfoCookie.getValue('sms_promotions');
          var $phoneNumberInput = $('.js-commercial_consent_phone_number', context);
          if (smsPromotions === '1' && phoneNumber) {
            $phoneNumberInput.val(phoneNumber);
          }
          if (window.site.userInfoCookie.getValue('pc_email_optin') === '1') {
            var $commercialPermissionChechbox = $('.js-commercial_consent', context);
            $commercialPermissionChechbox.prop('checked', true);
            $phoneNumberInput.removeClass('hidden');
          }
        }
      });
    },
    sentLoyaltyFormRequest: function (context) {
      var userInfoCookie = window.site.userInfoCookie;

      if (Boolean($.cookie('sent_lyl_req')) && Boolean(userInfoCookie.getValue('is_loyalty_member'))) {
        generic.jsonrpc.fetch({
          method: 'rpc.form',
          params: [
            {
              _SUBMIT: 'registration',
              SKIP_TITLE: '1',
              PC_EMAIL_PROMOTIONS: userInfoCookie.getValue('pc_email_optin') ? userInfoCookie.getValue('pc_email_optin') : '',
              SMS_PROMOTIONS: userInfoCookie.getValue('sms_promotions') ? userInfoCookie.getValue('sms_promotions') : '',
              MOBILE_NUMBER: userInfoCookie.getValue('mobile_number') ? userInfoCookie.getValue('mobile_number') : '',
              ACCEPTED_PRIVACY_POLICY: userInfoCookie.getValue('pc_email_optin') ? userInfoCookie.getValue('pc_email_optin') : '',
              ACCEPTED_LOYALTY_TERMS: userInfoCookie.getValue('is_loyalty_member') ? userInfoCookie.getValue('is_loyalty_member') : '',
              LOYALTY_ACTIVE_FLAG: userInfoCookie.getValue('is_loyalty_member') ? userInfoCookie.getValue('is_loyalty_member') : '',
              PC_EMAIL_ADDRESS: userInfoCookie.getValue('email') ? userInfoCookie.getValue('email') : ''
            }
          ],
          onSuccess: function (jsonRpcResponse) {
            var data = jsonRpcResponse.getData();
            var currentPoints = typeof data.dataLayer === 'object' &&
              typeof data.dataLayer.datalayer_events === 'object' &&
              typeof data.dataLayer.datalayer_events.registration === 'object' &&
              typeof data.dataLayer.datalayer_events.registration.event_data === 'object' &&
              typeof data.dataLayer.datalayer_events.registration.event_data.loyalty_current_points === 'number' ?

              data.dataLayer.datalayer_events.registration.event_data.loyalty_current_points :
              0;

            $('.user-logged-in__link .js-loyalty-points-value', context).html(currentPoints);
            $.cookie('sent_lyl_req', null, { path: '/' });
            $.cookie('sent_lyl_req_ok', 1, { path: '/' });
          }
        });
      }
    },
  multiImg : function(context){
    if( $('.loyalty_multi_image_tout_right').length ){
      var i=0;
      var random;
      var sequence = [];
      var position = 0;
      var time_per_image = $('[data-time_per_img]', context).attr('data-time_per_img');
      while ( i < $('.loyalty_multi_image_tout_right img').length ){
        random = Math.floor( Math.random() * $('.loyalty_multi_image_tout_right img', context).length )
        if ( !$('.loyalty_multi_image_tout_right img', context).eq(random).hasClass('processed') ){
          $('.loyalty_multi_image_tout_right img', context).eq(random).addClass('processed');
          sequence.push(random);
          i++;
        }
      }
      function rotate_img() {
        position = (position === sequence.length-1) ? 0 : position+1;
        $('.loyalty_multi_image_tout_right img').addClass('hidden');
        $('.loyalty_multi_image_tout_right img').eq(sequence[position]).removeClass('hidden');
        setTimeout(rotate_img, time_per_image * 1000);
      }
      rotate_img();
    }
  },
  enrollmentBtn : function(context) {
    var $joinBtn = $('.js-join-popup',context);
    var $commConsentCheckbox, $commConsentPhoneNumber;
    if (adv_legal) {
      $commConsentCheckbox = $('input#commercial_consent', context);
      $commConsentPhoneNumber = $('input#commercial_consent_phone_number', context);
      $commConsentCheckbox.on('change', function() {
        if ($commConsentCheckbox.is(':checked')) {
          $commConsentPhoneNumber.removeClass('hidden');
          return;
        }
        $commConsentPhoneNumber.addClass('hidden');
      });
    }
    $joinBtn.click(function(event) {
      event.preventDefault();
        var signedIn = site.userInfoCookie.getValue('signed_in') - 0;

        if (typeof site?.track?.loyaltyTag === 'function') {
          site.track.loyaltyTag('enrollment overlay');
        }
      if(signedIn) {
        if(Drupal.settings.globals_variables.loyalty_join_now_btn == 1){
            // Send them to account landing
            if (window.self !== window.top &&
              window.top.location.origin === window.location.origin) {
              window.top.location.href = Drupal.settings.globals_variables.loyalty_enroll_url;
            } else {
              window.location.href = Drupal.settings.globals_variables.loyalty_enroll_url;
            }

            return false;
        }
        var params = {};
        params['_SUBMIT'] = 'loyalty_join';
        params['LOYALTY_ACTIVE_FLAG'] = '1';

        if (adv_legal) {
          var $loyaltyTermsCheckbox = $('#loyalty_elist__loyalty_checkbox', context);
          var $loyaltyErrorMsgs = $('.js-loyalty-errors', context);
          $loyaltyErrorMsgs.find('li').addClass('hidden');
          if ($commConsentCheckbox.is(':checked')
              && $commConsentPhoneNumber.val() !== false
              && !/^([0-9][ -]*){8,12}\d$/.test($commConsentPhoneNumber.val())) {
            $loyaltyErrorMsgs.find('.validation_error_phone_number').removeClass('hidden');
            return false;
          }
          if (!$commConsentCheckbox.is(':checked')) {
            $loyaltyErrorMsgs.find('.validation_error_commercial_consent').removeClass('hidden');
            return false;
          }
          if (!$loyaltyTermsCheckbox.is(':checked')) {
            $loyaltyErrorMsgs.find('.validation_error_loyalty_join_consent').removeClass('hidden');
            return false;
          }
        }
        var require_loyalty_terms_acceptance = 0;
        var field = $('.loyalty_join_signin input[name="_SUBMIT"]'), undef;
        if (field != undef && field.length > 0) {
          require_loyalty_terms_acceptance = 1;
          params['_SUBMIT'] = field.val();
          params['profile_loyalty_join'] = '1';
          params['PC_EMAIL_ADDRESS'] = site.userInfoCookie.getValue('email');
          params['LOYALTY_EMAIL_PROMOTIONS'] = '1';
          field = $('.loyalty_join_signin input[name="ACCEPTED_LOYALTY_TERMS"]'), undef;
            var privacy_field = $('.loyalty_join_signin input[name="ACCEPTED_PRIVACY_POLICY"]');
            var isUncheckedBox;
            if (field !== undef && field.length > 0) {
              isUncheckedBox = field.is(':checkbox') && !field.prop('checked');
              params['ACCEPTED_LOYALTY_TERMS'] = isUncheckedBox ? '' : field.val();
            }
            if (privacy_field !== undefined && privacy_field.length > 0) {
              isUncheckedBox = privacy_field.is(':checkbox') && !privacy_field.prop('checked');
              params['ACCEPTED_PRIVACY_POLICY'] = isUncheckedBox ? '' : privacy_field.val();
            }
        }
        if (gdpr_sms) {
          var $smsPromoCheckbox = $('.loyalty_join_signin input[name=SMS_PROMOTIONS]', context);
          var $emailPromoCheckbox = $('.loyalty_join_signin input[name=PC_EMAIL_PROMOTIONS]', context);
          var $phoneNumber = $('.loyalty_join_signin input[name=PHONE_NUMBER]', context);
          if ($smsPromoCheckbox !== undefined && $smsPromoCheckbox.length > 0) {
            params['SMS_PROMOTIONS'] = $smsPromoCheckbox.is(':checked') ? '1' : 0;
            params['PC_EMAIL_PROMOTIONS'] = $emailPromoCheckbox.is(':checked') ? '1' : 0;
            params['PHONE_NUMBER'] = $phoneNumber.val();
          }
        }
        if (adv_legal) {
          if ($commConsentCheckbox !== undefined && $commConsentCheckbox.length > 0) {
            params['_SECONDARY_SUBMIT'] = 'sms';
            params['COMMERCIAL_PERMISSION'] = $commConsentCheckbox.is(':checked') ? '1' : 0;
            params['PC_EMAIL_PROMOTIONS'] = $commConsentCheckbox.is(':checked') ? '1' : 0;
            if ($commConsentPhoneNumber.val() !== '') {
              params['SMSPROMO_MOBILE_NUMBER'] = $commConsentPhoneNumber.val();
              params['SMSPROMO_SMS_PROMOTIONS'] = $commConsentCheckbox.is(':checked') ? '1' : 0;
            }
          }
        }
        generic.jsonrpc.fetch({
          method: 'rpc.form',
          params: [params],
          onSuccess: function(jsonRpcResponse) {
              if (typeof site?.track?.loyaltyTag === 'function') {
                site.track.loyaltyTag('enrollment overlay');
              }

              if (window.self !== window.top &&
                window.top.location.origin === window.location.origin) {
                window.top.location.href = '/account/loyalty/index.tmpl';
              } else {
                window.location.href = '/account/loyalty/index.tmpl';
              }
          },
          onFailure: function(jsonRpcResponse) {
            // display error
            console.log('error in joining');
              $('div.error').remove();
              $('label', '.loyalty_join_signin').removeClass('error');
              if (require_loyalty_terms_acceptance) {
                var messages = jsonRpcResponse.getMessages();
                $.each(messages, function(i, message) {
                  var $input, $formContainer = $('.loyalty_join_signin');
                  if (message.key === 'required.accepted_loyalty_terms.loyalty_email_signup') {
                    $input = $('.terms-checkbox-label', $formContainer);
                    $formContainer.after('<div class="error">' + message.text + '</div>');
                    $input.addClass('error');
                  }
                  if (message.key === 'required.accepted_privacy_policy.loyalty_email_signup') {
                    $input = $('.acceptance-checkbox-label', $formContainer);
                    $formContainer.after('<div class="error">' + message.text + '</div>');
                    $input.addClass('error');
                  }
              });
            }
          }
        });
      }
      // show a popup so the user can enter their email
      else {
        if(Drupal.settings.globals_variables.loyalty_join_now_btn == 1){
            // Send them to create account
            if (window.self !== window.top &&
              window.top.location.origin === window.location.origin) {
              window.top.location.href = Drupal.settings.globals_variables.account_enroll_url;
            } else {
              window.location.href = Drupal.settings.globals_variables.account_enroll_url;
            }
        }
        else{
          $(document).trigger('loyalty.show_signup_form', [{joinNowButtonClicked: true}]);
        }
      }
    });
  },
  signInBtn : function(context) {
      var $signInBtn = $('.js-sign-in-popup', context);
      var isIframe = window.self !== window.top
        && window.top.location.origin === window.location.origin;

      $signInBtn.click(function (event) {
        event.preventDefault();
        if (typeof site?.track?.loyaltyTag === 'function') {
          site.track.loyaltyTag('enrollment overlay');
        }
        // Trigger sign in button or send to signin
        if ($('.device-pc').length > 0 && !isIframe) {
          $('.page-utilities__signin-text').click();
        } else if (isIframe) {
          $.cookie('showsignin', 1);
          window.top.location.href = '/account/signin.tmpl';
        } else {
          $.cookie('showsignin', 1);
          window.location.href = '/account/signin.tmpl';
        }
      });
  },
  checkLoyaltyPageAccess : function() {
    // a check table of products and the minimum tier needed to access the product
    // example: 36143:3 (product 36143 is restricted to tier 3 members)
    var checkTable = {};

    //product/689/36143/Product-Catalog/Skincare/By-Category/Repair-Serums/New-Dimension/Shape-Fill-Expert-Serum
    var url = window.location.pathname;
    var pathArray = window.location.pathname.split( '/' );
    // this will return 36143
    var product = pathArray[3];

    return checkTable[product] ? checkTable[product] : 0;
  },
  loyaltyNavLinks : function() {
    window.scroll(0,0);
    $('.account-utilities__account-details-elist a').each(function(index) {
      var ele = this;
      if ((window.location.hash != "") && (this.href.indexOf(window.location.hash) > -1)) {
        $(this).addClass('active');
      } else {
        $(this).removeClass('active');
      }
    });
  }
};

Drupal.behaviors.ELB_loyalty = {
  attach: function(context, settings) {
    var self = this;
    //site.loyalty();
    $(window).on('hashchange',function(){
      var pageMainNode = $('.page-main')
      if(pageMainNode.hasClass('loyalty-account-page')) {
        site.loyalty.loyaltyNavLinks();
      };
    }).trigger('hashchange');
  }
};

})(jQuery);
